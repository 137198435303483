import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  paperWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacing(24),
  },
  descriptionWrapper: {
    margin: spacing(24, 0),
  },
  descriptionTitle: {
    color: palette.gray[80],
    marginBottom: spacing(12),
  },
  description: {
    color: palette.gray[64],
    lineHeight: spacing(20),
  },
  divider: {
    color: palette.gray[12],
    margin: spacing(24, 0),
  },
  button: {
    gap: '8px',
    height: 'fit-content',
  },
  input: {
    '& .MuiOutlinedInput-multiline': {
      alignItems: 'baseline',
      minHeight: spacing(152),
    },
  },
  icon: {
    height: spacing(14),
    width: spacing(14),
  },
  checkIcon: {
    height: spacing(16),
    width: spacing(16),
  },
  edit: {
    marginBottom: spacing(8),
    fontWeight: 'normal',
  },
  originalDescriptionButton: {
    marginRight: spacing(32),
    marginBottom: spacing(8),
    fontWeight: 'normal',
  },
  saveButton: {
    marginBottom: spacing(8),
    fontWeight: 'normal',
    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },
  cancelButton: {
    margin: spacing(0, 8, 8),
  },
  descriptionLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lines: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    width: 'fit-content',
  },
  columns: {
    display: 'flex',
    gap: spacing(8),
  },
}))
