import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as Modal from '_components/modal/generic-modal'
import { Loader } from '@refera/ui-web'

import useStyles from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { Checkbox, DateTimePicker, Select } from '_/components/inputs'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { Grid, Typography } from '@mui/material'
import { getTradesmanSequenceResults } from '_/modules/tradesman-sequence/selectors'
import moment from 'moment'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { REGISTER_STATUS } from '_/utils/constants'
import { getProvidersWithoutPagination } from '_/modules/provider/actions'
import { useParams } from '@reach/router'
import { providersResultWithoutPaginationSelector } from '_/modules/provider/selectors'
import { acceptByTradesman, acceptIBByTradesman } from '_/modules/tradesman-sequence/actions'
import { getServiceOrder } from '_/modules/service-orders/actions'
import { getStepStatusLog } from '_/modules/budget/actions'
import { getMessagingByServiceOrderId } from '_/modules/mensageria/actions'
import { ROLE_GROUP_CHOICES } from '_/utils/constants/service-order'
import { openWhatsAppWithMessage } from '_/utils/open-whatsApp'

export default function AcceptByTradesmanModal({ open, onClose, isInnerBudget = false }) {
  const { serviceOrderId } = useParams()
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const [loading, setLoading] = useState(false)
  const tradesmanSequenceResults = useSelector(getTradesmanSequenceResults)
  const providersList = useSelector(providersResultWithoutPaginationSelector)

  const defaultTradesman = useMemo(() => {
    const index = tradesmanSequenceResults?.length - 1
    if (!tradesmanSequenceResults?.[index]?.poolDeadline) {
      return null
    }

    return tradesmanSequenceResults?.[index]?.candidates?.[0]?.serviceProvider?.id
  }, [tradesmanSequenceResults])

  function formatDateTime(dateStr, startTime, endTime) {
    return `${moment(dateStr).format('DD/MM/YYYY')} das ${moment(startTime, 'HH:mm:ss').format(
      'HH:mm'
    )} às ${moment(endTime, 'HH:mm:ss').format('HH:mm')}`
  }

  const defaultFilters = useMemo(() => {
    return serviceOrder
      ? {
          ...(serviceOrder.getIn(['property', 'city']) && {
            cities: serviceOrder
              .getIn(['property', 'city'])
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .replaceAll(' ', '_'),
          }),
          ...(serviceOrder.category?.first?.()?.get('id') && {
            mainServices: serviceOrder.category?.first?.()?.get('id'),
          }),
          registrationStatus: REGISTER_STATUS.COMPLETE,
          serviceOrder: serviceOrderId,
        }
      : {}
  }, [serviceOrder, serviceOrderId])

  useEffect(() => {
    dispatch(getProvidersWithoutPagination(defaultFilters)).catch(() => {
      showToast({ type: 'error' })
    })
  }, [dispatch])

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      tradesman: defaultTradesman,
      notDateNegotiation: false,
      dateAndTimeNegotiation: null,
    },
  })

  const { handleSubmit, errors, watch } = methods

  const disabledDateTimePicker = watch('notDateNegotiation') === true

  const onSubmit = useCallback(
    async data => {
      setLoading(true)

      try {
        if (isInnerBudget)
          await dispatch(
            acceptIBByTradesman(serviceOrderId, {
              tradesmanId: data.tradesman,
            })
          )
        else
          await dispatch(
            acceptByTradesman(serviceOrderId, {
              tradesmanId: data.tradesman,
              dateAndTimeNegotiation: data.notDateNegotiation ? null : data.dateAndTimeNegotiation,
            })
          )

        await Promise.all([
          dispatch(getServiceOrder(serviceOrderId)).then(async responseSO => {
            await dispatch(
              getMessagingByServiceOrderId({
                serviceOrderId,
                destination: ROLE_GROUP_CHOICES.TRADESMAN_GROUP,
              })
            ).then(response => {
              if (response?.whatsappText)
                openWhatsAppWithMessage({
                  phone: responseSO?.tradesmanPhone,
                  message: response.whatsappText,
                })
            })
          }),
          dispatch(getStepStatusLog(serviceOrderId)),
        ])
        showToast({ type: 'success', message: 'Aceite realizado com sucesso' })
        onClose()
      } catch (error) {
        showToast({ type: 'error' })
      } finally {
        setLoading(false)
      }
    },
    [onClose]
  )

  return (
    <Modal.Root open={open} onClose={onClose} maxWidth="md">
      <Modal.TitleModal
        title={
          isInnerBudget
            ? 'Aceite de Orçamento Interno pelo prestador'
            : 'Aceite de chamado pelo prestador'
        }
      />

      <Modal.Content className={styles.container}>
        <FormProvider {...methods}>
          <form className={styles.box} id="formEmail">
            <Grid className={styles.column}>
              <Typography className={styles.title}>
                {`Selecione no campo abaixo o prestador que você está aceitando o ${
                  isInnerBudget ? 'orçamento' : 'chamado'
                } por ele.`}
              </Typography>
            </Grid>
            <Grid className={styles.column}>
              <Select
                label="Prestador"
                name="tradesman"
                defaultValue={defaultTradesman}
                required
                error={!!errors?.tradesman}
                options={providersList}
                getLabel={item => item.name}
                getValue={item => item.id}
                getKey={item => item.id}
                menuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                }}
                style={{ gridArea: 'businessFront' }}
                containerClasses={styles.select}
              />
            </Grid>

            {!isInnerBudget && (
              <>
                <Grid className={styles.column}>
                  <Typography className={styles.text}>Sugestões do solicitante</Typography>

                  <Typography className={styles.text}>
                    {formatDateTime(
                      serviceOrder?.firstSuggestionDate,
                      serviceOrder?.firstStartTime,
                      serviceOrder?.firstEndTime
                    )}
                  </Typography>
                  <Typography className={styles.text}>
                    {formatDateTime(
                      serviceOrder?.secondSuggestionDate,
                      serviceOrder?.secondStartTime,
                      serviceOrder?.secondEndTime
                    )}
                  </Typography>
                  <Typography className={styles.text}>
                    {formatDateTime(
                      serviceOrder?.thirdSuggestionDate,
                      serviceOrder?.thirdStartTime,
                      serviceOrder?.thirdEndTime
                    )}
                  </Typography>
                </Grid>

                <Grid className={styles.column}>
                  <Checkbox
                    label="Deixar prestador negociar a data com cliente"
                    name="notDateNegotiation"
                    defaultValue={false}
                    containerClasses={styles.checkboxContainer}
                  />
                </Grid>

                <Grid className={styles.column}>
                  <DateTimePicker
                    name="dateAndTimeNegotiation"
                    label="Data e hora de visita para orçar"
                    referaDatePickerProps={{ disablePast: true, disabled: disabledDateTimePicker }}
                    required={!disabledDateTimePicker}
                  />
                </Grid>
              </>
            )}
          </form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose} disabled={loading}>
          Cancelar
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue form="formEmail" onClick={handleSubmit(onSubmit)} disabled={loading}>
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
      <Loader hasBackdrop open={loading} />
    </Modal.Root>
  )
}
