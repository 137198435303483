import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateBudget } from '_modules/budget/actions'
import * as Modal from '_components/modal/generic-modal'

const CancelSentEmailRevised = ({
  isOpen,
  onClose,
  serviceOrderId,
  budgetId,
  handleCancelRevise,
}) => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)

  const onCancelButtonRevision = useCallback(() => {
    setLoading(true)
    dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: false }))
      .then(() => {
        if (isOpen) {
          handleCancelRevise()
          onClose()
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }, [budgetId, dispatch, serviceOrderId, isOpen])

  return (
    <Modal.Root open={isOpen} onClose={onClose} aria-describedby="cancelReviseBudgetDialog">
      <Modal.Content>
        <Modal.WarningIcon />
        <Modal.Text>
          Este orçamento já foi enviado ao pagador. Você tem certeza que deseja cancelar sua revisão
          e habilitar sua edição?
        </Modal.Text>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose}>Não</Modal.ButtonRed>
        <Modal.ButtonFullBlue
          onClick={onCancelButtonRevision}
          disabled={isLoading}
          color="primary"
          variant="contained"
        >
          Sim
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}

CancelSentEmailRevised.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
  handleCancelRevise: PropTypes.func.isRequired,
}

export default React.memo(CancelSentEmailRevised)
