import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateBudget } from '_modules/budget/actions'
import * as Modal from '_components/modal/generic-modal'

const CancelReviseBudgetDialog = ({ isOpen, onClose, serviceOrderId, budgetId }) => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const onCancelButtonRevision = useCallback(() => {
    setLoading(true)
    dispatch(updateBudget(serviceOrderId, budgetId, { isRevised: false }))
      .then(() => {
        setLoading(false)
        onClose()
      })
      .catch(() => setLoading(false))
  }, [budgetId, dispatch, serviceOrderId, onClose])

  return (
    <Modal.Root open={isOpen} onClose={onClose} aria-describedby="cancelReviseBudgetDialog">
      <Modal.Content>
        <Modal.WarningIcon />
        <Modal.Text>Você tem certeza que deseja executar esta ação?</Modal.Text>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={onClose} variant="outlined">
          Não
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue
          onClick={onCancelButtonRevision}
          disabled={isLoading}
          color="primary"
          variant="contained"
        >
          Sim
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}

CancelReviseBudgetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
}

export default React.memo(CancelReviseBudgetDialog)
